<template>
  <v-container id="flotillas" tag="section">
    <base-material-card
      class="px-5 py-3"
      color="primary"
      icon="mdi-bus-multiple"
      inline
      :title="$t('fleets')"
    >
      <v-spacer></v-spacer>
      <template v-slot:after-heading></template>

      <!-- Boton nuevo registro -->
      <template v-slot:corner-button>
        <v-btn small class="ma-2" elevation="2" color="primary" @click.stop="dialog = true"
          >Nuevo Vehículo</v-btn
        >
      </template>

      <!-- Overlay para retroalimentar al usuario -->
      <v-overlay :value="overlay">
        <v-progress-circular color="success" indeterminate :size="50"></v-progress-circular>
      </v-overlay>

      <!-- Textfield para la barra de Búsqueda -->
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        class="ml-auto"
        label="Búsqueda"
        hide-details
        single-line
        style="max-width: 250px"
      />

      <!-- Dialogo Cancelar -->
      <ConfirmationDialog
        buttonColor1="error"
        buttonText1="NO"
        buttonColor2="primary"
        buttonText2="SI"
        text="Seguro quiere cancelar?"
        title="¡Se perderan todos los cambios!"
        v-model="isSure"
        :width="420"
        @click="cancel"
      />

      <!-- Dialogo Agregar/Editar Flotilla -->
      <v-dialog v-model="dialog" max-width="600px">
        <v-card flat>
          <!-- <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title> -->

          <v-card-text>
            <base-subheading :subheading="formTitle" />
            <v-tabs color="success" background-color="transparent" v-model="tab" right>
              <v-tab v-for="item in items" :key="item">
                {{ item }}
              </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>

            <v-tabs-items v-model="tab">
              <!-- Tab Vehiculo -->
              <v-tab-item key="Vehiculo">
                <v-container>
                  <v-row>
                    <!-- Lista de seleccion en el formulario para el propietario de vehiculo -->
                    <v-col cols="12">
                      <v-text-field
                        label="Propietario*"
                        maxlength="30"
                        required
                        v-model="$v.editedItem.propietario.$model"
                        :error-messages="propietarioErrors"
                        @blur="$v.editedItem.propietario.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.propietario.$reset()"
                      ></v-text-field>
                    </v-col>
                    <!-- Lista de seleccion en el formulario para la marca de vehiculo -->
                    <v-col cols="4">
                      <v-select
                        dense
                        hint="Seleciona Marca"
                        item-text="marca"
                        item-value="_id"
                        label="Marca*"
                        persistent-hint
                        required
                        v-model="editedItem.marca"
                        :items="marcas"
                        :menu-props="{ top: true, offsetY: true }"
                      ></v-select>
                      {{ editedItem.marcas }}
                    </v-col>
                    <!-- Campo de texto en el formulario formulario pare el tipo de vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Tipo*"
                        required
                        v-model="$v.editedItem.tipo.$model"
                        :error-messages="tipoErrors"
                        @blur="$v.editedItem.tipo.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.tipo.$reset()"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario formulario para el modelo de vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Modelo*"
                        maxlength="4"
                        required
                        v-model="$v.editedItem.modelo.$model"
                        :error-messages="modeloErrors"
                        @blur="$v.editedItem.modelo.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.modelo.$reset()"
                        @keypress="isNumber($event)"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para la capacidad de pasajeros de vehiculo -->
                    <v-col cols="6">
                      <v-text-field
                        dense
                        label="Capacidad Pasajeros*"
                        maxlength="2"
                        required
                        v-model="$v.editedItem.capacidad.pasajeros.$model"
                        :error-messages="pasajerosErrors"
                        @blur="$v.editedItem.capacidad.pasajeros.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.capacidad.pasajeros.$reset()"
                        @keypress="isNumber($event)"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para la capacidad de equipaje de vehiculo -->
                    <v-col cols="6">
                      <v-text-field
                        dense
                        label="Capacidad Equipaje*"
                        maxlength="3"
                        required
                        v-model="$v.editedItem.capacidad.equipaje.$model"
                        :error-messages="equipajeErrors"
                        @blur="$v.editedItem.capacidad.equipaje.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.capacidad.equipaje.$reset()"
                        @keypress="isNumber($event)"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para el numero económico de vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Nº Económico*"
                        maxlength="30"
                        required
                        v-model="$v.editedItem.numero_economico.$model"
                        :error-messages="numeroEconomicoErrors"
                        @blur="$v.editedItem.numero_economico.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.numero_economico.$reset()"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para el numero de serie de vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Nº Serie*"
                        maxlength="30"
                        required
                        v-model="$v.editedItem.numero_serie.$model"
                        :error-messages="numeroSerieErrors"
                        @blur="$v.editedItem.numero_serie.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.numero_serie.$reset()"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para el numero de motor de vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Nº Motor*"
                        maxlength="30"
                        v-model="editedItem.numero_motor"
                        @change="onTextChange"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario la placa del vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Placa*"
                        maxlength="10"
                        required
                        v-model="$v.editedItem.placa.$model"
                        :error-messages="placaErrors"
                        @blur="$v.editedItem.placa.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.placa.$reset()"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario el color del vehiculo -->
                    <v-col cols="4">
                      <v-text-field
                        dense
                        label="Color*"
                        maxlength="15"
                        required
                        v-model="$v.editedItem.color.$model"
                        :error-messages="colorErrors"
                        @blur="$v.editedItem.color.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.color.$reset()"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!-- Tab Aseguradora -->
              <v-tab-item key="Aseguradora">
                <v-container>
                  <v-row>
                    <!-- Campo de texto en el formulario para la aseguradora del vehiculo -->
                    <v-col cols="6">
                      <v-text-field
                        label="Aseguradora"
                        maxlength="30"
                        required
                        v-model="$v.editedItem.seguro.aseguradora.$model"
                        :error-messages="aseguradoraErrors"
                        @blur="$v.editedItem.seguro.aseguradora.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.seguro.aseguradora.$reset()"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para la poliza del vehiculo -->
                    <v-col cols="6">
                      <v-text-field
                        label="Nº Poliza*"
                        maxlength="30"
                        required
                        v-model="$v.editedItem.seguro.poliza.$model"
                        :error-messages="polizaErrors"
                        @blur="$v.editedItem.seguro.poliza.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.seguro.poliza.$reset()"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para el costo del seguro del vehiculo -->
                    <v-col cols="6">
                      <v-text-field
                        dense
                        label="Costo"
                        maxlength="8"
                        required
                        v-model="$v.editedItem.seguro.costo.$model"
                        :error-messages="costoErrors"
                        @blur="$v.editedItem.seguro.costo.$touch()"
                        @change="onTextChange"
                        @input="$v.editedItem.seguro.costo.$reset()"
                        @keypress="isNumber($event)"
                      ></v-text-field>
                    </v-col>

                    <!-- Campo de texto en el formulario para vencimiento del seguro del vehiculo -->
                    <v-col cols="6">
                      <!-- <v-layout row wrap> -->
                      <v-menu
                        min-width="auto"
                        offset-y
                        transition="scale-transition"
                        v-model="mnuVencimiento"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <v-text-field
                            dense
                            label="Vencimiento Seguro"
                            prepend-icon="mdi-calendar-month"
                            readonly
                            required
                            v-on="on"
                            :error-messages="vencimientoErrors"
                            :value="vencimientoDateDisp"
                            @blur="$v.editedItem.seguro.vencimiento.$touch()"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es-MX"
                          no-title
                          v-model="$v.editedItem.seguro.vencimiento.$model"
                          :min="minDate"
                          @change="onTextChange"
                          @input="mnuVencimiento = false"
                        ></v-date-picker>
                      </v-menu>
                      <!-- </v-layout> -->
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <!-- Tab Verificación -->
              <v-tab-item key="Verificación">
                <v-card flat class="my-0">
                  <v-container fluid>
                    <v-row>
                      <!-- Campo de texto en el formulario para fecha de verificación de humo -->
                      <v-col cols="6">
                        <v-menu
                          min-width="auto"
                          offset-y
                          transition="scale-transition"
                          v-model="mnuHumo"
                          :close-on-content-click="false"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              label="Verificación Humo*"
                              prepend-icon="mdi-calendar-month"
                              readonly
                              required
                              v-on="on"
                              :error-messages="humoErrors"
                              :value="humoDateDisp"
                              @blur="$v.editedItem.verificacion.humo.$touch()"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            locale="es-MX"
                            no-title
                            v-model="$v.editedItem.verificacion.humo.$model"
                            :min="minDate"
                            @change="onTextChange"
                            @input="mnuHumo = false"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>

                      <!-- Campo de texto en el formulario para fecha de verificación mecánica -->
                      <v-col cols="6">
                        <v-layout row wrap>
                          <v-menu
                            min-width="auto"
                            offset-y
                            transition="scale-transition"
                            v-model="mnuMecanica"
                            :close-on-content-click="false"
                          >
                            <template v-slot:activator="{ on }">
                              <v-text-field
                                label="Verificación Mecánica"
                                prepend-icon="mdi-calendar-month"
                                readonly
                                v-on="on"
                                :error-messages="mecanicaErrors"
                                :value="mecanicaDateDisp"
                                @blur="$v.editedItem.verificacion.mecanica.$touch()"
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              locale="es-MX"
                              no-title
                              v-model="$v.editedItem.verificacion.mecanica.$model"
                              :min="minDate"
                              @change="onTextChange"
                              @input="mnuMecanica = false"
                            ></v-date-picker>
                          </v-menu>
                        </v-layout>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
            <small>*Campo Obligatorio</small>
          </v-card-text>

          <!-- Botones y CheckList -->
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-checkbox
              label="Crear otro"
              v-if="editedIndex === -1"
              v-model="checkbox"
              :disabled="isEditing"
            ></v-checkbox>

            <!-- Botones de guardar y cancelar -->
            <v-btn color="error" text @click="checkChanges">Cancelar</v-btn>
            <v-btn color="primary" text @click="save" :disabled="$v.$invalid">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-divider class="mt-3" />

      <!-- Tabla de datos de Flotillas  -->
      <v-data-table
        dense
        loading-text="Cargando... Espere por favor"
        show-expand
        :expanded.sync="expanded"
        :headers="headers"
        :items="vehiculos"
        :loading="isLoading"
        :search.sync="search"
        :single-expand="singleExpand"
        item-key="numero_economico"
      >
        <!-- Slot de vehiculo -->
        <template v-slot:[`item.marca`]="{ item }">
          <TwoFieldsOneColumn
            title1="Marca"
            title2="Tipo"
            :line1="item.marca.marca"
            :line2="item.tipo"
        /></template>
        <!-- Termina Slot de vehiculo -->

        <!-- Slot de capacidades -->
        <template v-slot:[`item.capacidad`]="{ item }">
          <TwoFieldsOneColumn
            title1="Pax"
            title2="Equ"
            :line1="item.capacidad.pasajeros"
            :line2="item.capacidad.equipaje"
        /></template>
        <!-- Termina Slot de capacidades -->

        <!-- Slot de acciones -->
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
          <v-icon small @click="deleteItem(item)">mdi-delete</v-icon>
        </template>
        <!-- Termina Slot de acciones -->

        <!-- Slot botón activo -->
        <template v-slot:[`item.activo`]="{ item }">
          <Status :activo="item.activo" />
        </template>
        <!-- Termina Slot botón activo -->

        <!-- Empieza expanded item -->
        <template v-slot:[`expanded-item`]="{ headers, item }">
          <td
            :colspan="headers.length / 5"
            style="font-size: 83%; padding: 5px 5px 5px 20px; vertical-align: top"
          >
            <h5>SEGURO:</h5>
            <!-- <br /> -->
            <b>Aseguradora:</b> <i>{{ item.seguro.aseguradora }}</i> <br />
            <b>Poliza:</b> <i>{{ item.seguro.poliza }}</i> <br />
            <b>Costo:</b> <i>{{ item.seguro.costo }}</i> <br />
            <b>Vencimiento:</b> <i>{{ formatDate2(item.seguro.vencimiento) }}</i
            ><br />
            <br />
            <h5>VERIFICACIÓN:</h5>
            <b>Humo:</b> <i>{{ formatDate2(item.verificacion.humo) }}</i> &nbsp; <br />
            <b>Mecánica:</b> <i>{{ formatDate2(item.verificacion.mecanica) }}</i>
          </td>
        </template>
        <!-- Termina expanded item -->
      </v-data-table>

      <!-- DataExporter -->
      <DataExporter :dataArray="vehiculos" :dataFields="fields" fileName="Flotilla" />
    </base-material-card>
  </v-container>
</template>

<script>
import { minLength, maxLength, numeric, required } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import ConfirmationDialog from '@/components/general/ConfirmationDialog.vue'
import DataExporter from '@/components/general/DataExporter.vue'
import Status from '@/components/general/StatusInfo.vue'
import TwoFieldsOneColumn from '@/components/general/TwoFieldsOneColumn.vue'

export default {
  name: 'CatalogoFlotillas',
  components: {
    ConfirmationDialog,
    DataExporter,

    Status,
    TwoFieldsOneColumn,
  },
  data: () => ({
    // Variables
    changed: false,
    checkbox: false,
    dialog: false,
    editedIndex: -1,
    isEditing: false,
    isLoading: false,
    isSure: false,
    minDate: new Date().toISOString().substr(0, 10),
    mnuHumo: false,
    mnuMecanica: false,
    mnuVencimiento: false,
    overlay: false,
    search: undefined,
    singleExpand: true,
    tab: null,
    // Arreglos
    expanded: [],
    headers: [
      { text: 'Propietario', value: 'propietario' },
      { text: 'Vehiculo', value: 'marca' },
      { text: 'Modelo', value: 'modelo' },
      { text: 'Capacidad', value: 'capacidad' },
      { text: 'Nº Económico', align: 'start', sortable: false, value: 'numero_economico' },
      { text: 'Nº Serie', align: 'start', sortable: false, value: 'numero_serie' },
      { text: 'Nº Motor', align: 'start', sortable: false, value: 'numero_motor' },
      { text: 'Placa', value: 'placa' },
      { text: 'Color', value: 'color' },
      { text: 'Activo', value: 'activo' },
      { text: 'Acciones', value: 'actions' },
      { text: '', value: 'data-table-expand' },
    ],
    items: ['Vehículo', 'Aseguradora', 'Verificación'],
    marcas: [],
    vehiculos: [],
    // Objetos
    defaultItem: {
      _id: '',
      propietario: '',
      marca: '',
      tipo: '',
      modelo: '',
      capacidad: { pasajeros: '', equipaje: '' },
      numero_economico: '',
      numero_serie: '',
      numero_motor: '',
      seguro: { aseguradora: '', poliza: '', costo: '', vencimiento: null },
      verificacion: { humo: null, mecanica: null },
      placa: '',
      color: '',
      activo: true,
    },
    editedItem: {
      _id: '',
      propietario: '',
      marca: '',
      tipo: '',
      modelo: '',
      capacidad: { pasajeros: '', equipaje: '' },
      numero_economico: '',
      numero_serie: '',
      numero_motor: '',
      seguro: { aseguradora: '', poliza: '', costo: '', vencimiento: null },
      verificacion: { humo: null, mecanica: null },
      placa: '',
      color: '',
      activo: true,
    },
    fields: {
      Propietario: 'propietario',
      Marca: 'marca.marca',
      Tipo: 'tipo',
      Modelo: 'modelo',
      Pasajeros: 'capacidad.pasajeros',
      Equipaje: 'capacidad.equipaje',
      NºEconómico: 'numero_economico',
      NºSerie: 'numero_serie',
      NºMotor: 'numero_motor',
      Aseguradora: 'seguro.aseguradora',
      Poliza: 'seguro.poliza',
      Costo: 'seguro.costo',
      Vencimiento: 'seguro.vencimiento',
      Humo: 'verificacion.humo',
      Mecanica: 'verificacion.mecanica',
      Placa: 'placa',
      Color: 'color',
    },
  }),

  mixins: [validationMixin],

  //Validaciones para los campos del formulario
  validations: {
    editedItem: {
      propietario: { required, minLength: minLength(10), maxLength: maxLength(30) },
      tipo: { required, minLength: minLength(5), maxLength: maxLength(20) },
      modelo: { required, minLength: minLength(4), maxLength: maxLength(4), numeric },
      capacidad: {
        pasajeros: { required, minLength: minLength(1), maxLength: maxLength(2), numeric },
        equipaje: { required, minLength: minLength(2), maxLength: maxLength(3), numeric },
      },
      numero_economico: { required, minLength: minLength(4), maxLength: maxLength(30) },
      numero_serie: { required, minLength: minLength(8), maxLength: maxLength(30) },
      // numero_motor: { required, minLength: minLength(8), maxLength: maxLength(30) },
      seguro: {
        aseguradora: { required, minLength: minLength(8), maxLength: maxLength(30) },
        poliza: { required, minLength: minLength(8), maxLength: maxLength(30) },
        costo: { required, minLength: minLength(5), maxLength: maxLength(8), numeric },
        vencimiento: { required },
      },
      verificacion: {
        humo: { required },
        mecanica: { required },
      },
      placa: { required, minLength: minLength(6), maxLength: maxLength(10) },
      color: { required, minLength: minLength(4), maxLength: maxLength(15) },
    },
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Nuevo Vehículo' : 'Editar Vehículo'
    },
    humoDateDisp() {
      return this.editedItem.verificacion.humo
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    mecanicaDateDisp() {
      return this.editedItem.verificacion.mecanica
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    vencimientoDateDisp() {
      return this.editedItem.seguro.vencimiento
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },

    propietarioErrors() {
      const errors = []
      if (!this.$v.editedItem.propietario.$dirty) return errors
      !this.$v.editedItem.propietario.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.propietario.minLength &&
        errors.push('Este campo debe de tener un mínimo de 10 caracteres.')
      !this.$v.editedItem.propietario.maxLength &&
        errors.push('Este campo debe de tener un máximo de 30 caracteres.')
      return errors
    },
    tipoErrors() {
      const errors = []
      if (!this.$v.editedItem.tipo.$dirty) return errors
      !this.$v.editedItem.tipo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.tipo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 5 caracteres.')
      !this.$v.editedItem.tipo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      return errors
    },
    modeloErrors() {
      const errors = []
      if (!this.$v.editedItem.modelo.$dirty) return errors
      !this.$v.editedItem.modelo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.modelo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 5 caracteres.')
      !this.$v.editedItem.modelo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 20 caracteres.')
      !this.$v.editedItem.modelo.numeric && errors.push('Este campo debe de ser numerico.')
      return errors
    },
    pasajerosErrors() {
      const errors = []
      if (!this.$v.editedItem.capacidad.pasajeros.$dirty) return errors
      !this.$v.editedItem.capacidad.pasajeros.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.capacidad.pasajeros.minLength &&
        errors.push('Este campo debe de tener un mínimo de 1 caracteres.')
      !this.$v.editedItem.capacidad.pasajeros.maxLength &&
        errors.push('Este campo debe de tener un máximo de 2 caracteres.')
      !this.$v.editedItem.capacidad.pasajeros.numeric &&
        errors.push('Este campo debe de ser numerico.')
      return errors
    },
    equipajeErrors() {
      const errors = []
      if (!this.$v.editedItem.capacidad.equipaje.$dirty) return errors
      !this.$v.editedItem.capacidad.equipaje.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.capacidad.equipaje.minLength &&
        errors.push('Este campo debe de tener un mínimo de 2 caracteres.')
      !this.$v.editedItem.capacidad.equipaje.maxLength &&
        errors.push('Este campo debe de tener un máximo de 3 caracteres.')
      !this.$v.editedItem.capacidad.equipaje.numeric &&
        errors.push('Este campo debe de ser numerico.')
      return errors
    },
    numeroEconomicoErrors() {
      const errors = []
      if (!this.$v.editedItem.numero_economico.$dirty) return errors
      !this.$v.editedItem.numero_economico.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.numero_economico.minLength &&
        errors.push('Este campo debe de tener un mínimo de 4 caracteres.')
      !this.$v.editedItem.numero_economico.maxLength &&
        errors.push('Este campo debe de tener un máximo de 30 caracteres.')
      return errors
    },
    numeroSerieErrors() {
      const errors = []
      if (!this.$v.editedItem.numero_serie.$dirty) return errors
      !this.$v.editedItem.numero_serie.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.numero_serie.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.numero_serie.maxLength &&
        errors.push('Este campo debe de tener un máximo de 30 caracteres.')
      return errors
    },
    // numeroMotorErrors() {
    //   const errors = []
    //   if (!this.$v.editedItem.numero_motor.$dirty) return errors
    //   !this.$v.editedItem.numero_motor.required && errors.push('Este campo es requerido')
    //   !this.$v.editedItem.numero_motor.minLength &&
    //     errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
    //   !this.$v.editedItem.numero_motor.maxLength &&
    //     errors.push('Este campo debe de tener un máximo de 30 caracteres.')
    //   // !this.$v.editedItem.numero_motor.numeric && errors.push('Este campo debe de ser numerico.')
    //   return errors
    // },
    aseguradoraErrors() {
      const errors = []
      if (!this.$v.editedItem.seguro.aseguradora.$dirty) return errors
      !this.$v.editedItem.seguro.aseguradora.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.seguro.aseguradora.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.seguro.aseguradora.maxLength &&
        errors.push('Este campo debe de tener un máximo de 30 caracteres.')
      return errors
    },
    polizaErrors() {
      const errors = []
      if (!this.$v.editedItem.seguro.poliza.$dirty) return errors
      !this.$v.editedItem.seguro.poliza.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.seguro.poliza.minLength &&
        errors.push('Este campo debe de tener un mínimo de 8 caracteres.')
      !this.$v.editedItem.seguro.poliza.maxLength &&
        errors.push('Este campo debe de tener un máximo de 30 caracteres.')
      return errors
    },
    costoErrors() {
      const errors = []
      if (!this.$v.editedItem.seguro.costo.$dirty) return errors
      !this.$v.editedItem.seguro.costo.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.seguro.costo.minLength &&
        errors.push('Este campo debe de tener un mínimo de 5 caracteres.')
      !this.$v.editedItem.seguro.costo.maxLength &&
        errors.push('Este campo debe de tener un máximo de 8 caracteres.')
      !this.$v.editedItem.seguro.costo.numeric && errors.push('Este campo debe de ser numerico.')
      return errors
    },
    vencimientoErrors() {
      const errors = []
      if (!this.$v.editedItem.seguro.vencimiento.$dirty) return errors
      !this.$v.editedItem.seguro.vencimiento.required && errors.push('Este campo es requerido')
      return errors
    },
    humoErrors() {
      const errors = []
      if (!this.$v.editedItem.verificacion.humo.$dirty) return errors
      !this.$v.editedItem.verificacion.humo.required && errors.push('Este campo es requerido')
      return errors
    },
    mecanicaErrors() {
      const errors = []
      if (!this.$v.editedItem.verificacion.mecanica.$dirty) return errors
      !this.$v.editedItem.verificacion.mecanica.required && errors.push('Este campo es requerido')
      return errors
    },
    placaErrors() {
      const errors = []
      if (!this.$v.editedItem.placa.$dirty) return errors
      !this.$v.editedItem.placa.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.placa.minLength &&
        errors.push('Este campo debe de tener un mínimo de 6 caracteres.')
      !this.$v.editedItem.placa.maxLength &&
        errors.push('Este campo debe de tener un máximo de 10 caracteres.')
      return errors
    },
    colorErrors() {
      const errors = []
      if (!this.$v.editedItem.color.$dirty) return errors
      !this.$v.editedItem.color.required && errors.push('Este campo es requerido')
      !this.$v.editedItem.color.minLength &&
        errors.push('Este campo debe de tener un mínimo de 4 caracteres.')
      !this.$v.editedItem.color.maxLength &&
        errors.push('Este campo debe de tener un máximo de 15 caracteres.')
      return errors
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
      this.tab = 'Vehículo'
      // this.$v.$reset()
    },
  },

  created() {
    this.obtenerMarcas()
    this.obtenerFlotilla()
  },

  methods: {
    /**
     * @description Elimina un registro de la base de datos y del data table
     */
    async BorrarRegistro() {
      this.overlay = true
      this.$v.$touch()

      let payload = {}
      payload.id = this.editedItem._id
      this.$store
        .dispatch('fleet/deleteFleet', payload)
        .then(() => {
          this.$store.commit('ALERT', {
            color: 'success',
            text: 'El registro se elimino con éxito',
          })
          this.overlay = false
        })
        .catch((err) => {
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.msg,
          })
          this.overlay = false
        })
      this.editedItem = Object.assign({}, this.defaultItem)
    },

    /**
     * @description Verifica si esta seguro de salir
     */
    cancel(isSure) {
      this.isSure = isSure
      if (this.isSure) {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Verifica si se cambio el valor de algun campo
     */
    checkChanges() {
      if (this.changed) {
        this.isSure = true
      } else {
        this.close(false)
        this.changed = false
      }
    },

    /**
     * @description Cierra el dialogo
     * @param {bool} checkbox boleano que indica el estado del checkbox
     */
    close(checkbox) {
      this.isSure = false
      this.changed = false
      this.checkbox = checkbox
      this.isEditing = false
      if (!checkbox) {
        this.dialog = false
      }
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
      this.$v.$reset()
    },

    /**
     * @description Elimina una tarifa
     * @param {object} item elemento seleccionado
     */
    deleteItem(item) {
      const index = this.vehiculos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      if (confirm('Esta seguro que desea eliminar este registro?')) {
        this.BorrarRegistro()
        this.vehiculos.splice(index, 1)
      }
    },

    /**
     * @description Obtiene el indice del elemento seleccionado y lo signa al array editedItem
     * @param {object} item elemento seleccionado
     */
    editItem(item) {
      this.tab = 'Vehículo'
      this.isEditing = true
      this.editedIndex = this.vehiculos.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.seguro.vencimiento = this.formatDate2(item.seguro.vencimiento)
      this.editedItem.verificacion.humo = this.formatDate2(item.verificacion.humo)
      this.editedItem.verificacion.mecanica = this.formatDate2(item.verificacion.mecanica)
      this.dialog = true
    },

    /**
     * @description Obtiene las marcas de vehiculo
     * @param {string} jwt JSON web token
     */
    obtenerMarcas() {
      let payload = { params: '?campo1=marca&orden1=1' }
      this.$store
        .dispatch('brandVehicle/fetchBrandsVehicle', payload)
        .then((response) => {
          this.marcas = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Obtiene los vehículos
     * @param {string} jwt JSON web token
     */
    obtenerFlotilla() {
      this.isLoading = true
      let payload = {}
      this.$store
        .dispatch('fleet/fetchFleets', payload)
        .then((response) => {
          this.vehiculos = response.data
          this.isLoading = false
        })
        .catch((err) => {
          this.isLoading = false
          this.$store.commit('ALERT', {
            color: 'error',
            text: err.response.data.error,
            // Enviar a inicio de sesión
          })
        })
    },

    /**
     * @description Guarda el contenido del dialogo
     */
    async save() {
      this.overlay = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        let body = {
          propietario: this.editedItem.propietario,
          marca: this.editedItem.marca,
          tipo: this.editedItem.tipo,
          modelo: this.editedItem.modelo,
          capacidad: this.editedItem.capacidad,
          numero_economico: this.editedItem.numero_economico,
          numero_serie: this.editedItem.numero_serie,
          numero_motor: this.editedItem.numero_motor,
          seguro: this.editedItem.seguro,
          verificacion: this.editedItem.verificacion,
          placa: this.editedItem.placa,
          color: this.editedItem.color,
        }

        let payload = { body: body }
        if (this.editedIndex > -1) {
          payload.id = this.editedItem._id
          this.$store
            .dispatch('fleet/editFleet', payload)
            .then(() => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se actualizo con éxito',
              })
              this.overlay = false
            })
            .catch((err) => {
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
          Object.assign(this.vehiculos[this.editedIndex], this.editedItem)
        } else {
          this.$store
            .dispatch('fleet/addFleet', payload)
            .then((response) => {
              this.$store.commit('ALERT', {
                color: 'success',
                text: 'El registro se creó con éxito',
              })
              this.vehiculos.push(response.data)
              this.overlay = false
            })
            .catch((err) => {
              console.log(err)
              this.$store.commit('ALERT', {
                color: 'error',
                text: err.response.data.error,
                // Enviar a inicio de sesión
              })
              this.overlay = false
            })
        }
        this.close(this.checkbox)
      }
    },

    formatDate(date) {
      date = new Date(date)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()

      if (day < 10) {
        day = '0' + month
      }

      if (month < 10) {
        month = '0' + month
      }

      return `${year}-${month}-${day}`
    },

    formatDate2(date) {
      return new Date(date).toISOString().substr(0, 10)
    },

    // Evalua el caracter para saber si es un numero
    isNumber: function (evt, negativo = false) {
      evt = evt ? evt : window.event
      var charCode = evt.which ? evt.which : evt.keyCode

      if (negativo && charCode === 45) {
        return true
      }

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46 //&&
        // charCode !== 45
      ) {
        evt.preventDefault()
      } else {
        return true
      }
    },

    /**
     * @description Marca que ha cambiado el valor de un campo
     */
    onTextChange() {
      this.changed = true
    },
  },
}
</script>
